import { Alert, AlertDescription, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, AlertTitle, Box, Button, Code, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, GridItem, Icon, IconButton, Input, Select, SimpleGrid, Skeleton, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from "@chakra-ui/react";
import StationsApi from "Api/StationsApi";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { Field, Form, Formik } from "formik";
import Helper from "Helpers/Helper";
import { useLoading } from "Providers/LoadingProvider";
import { useEffect, useRef, useState } from "react";
import { FaHistory, FaList, FaPencilAlt, FaPlusCircle, FaSearch, FaTable, FaTrashAlt } from "react-icons/fa";
import { Pagination } from 'react-laravel-paginex';
import SingleMarkerMap from "views/Components/SingleMarkerMap";
import 'views/Components/pagination.css';
import ReactJson from 'react-json-view'

function Stations() {
    const [page, setPage] = useState(1);
    const [data, setData] = useState({});
    const [currentStation, setCurrentStation] = useState({});
    const loading = useLoading();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const normalRainClosure = useDisclosure();
    const [filter, setFilter] = useState('');

    const getData = async (param) => {
        loading.show();
        let currentPage = param ? param.page : (page || 1);
        setPage(currentPage);
        let response = await StationsApi.list(currentPage, filter);
        setData(response.json);
        loading.hide();
    }

    //Delete
    const [isAlertOpen, setAlertIsOpen] = useState(false);
    const onAlertClose = () => setAlertIsOpen(false);
    //Restore
    const [isRestoreAlertOpen, setRestoreAlertIsOpen] = useState(false);
    const onRestoreAlertClose = () => setRestoreAlertIsOpen(false);
    // Logs
    const logsClosure = useDisclosure();

    useEffect(() => {
        getData({ page: 1 });
    }, []);

    useEffect(() => {
        getData();
    }, [filter]);

    const onFilterSubmit = (formData) => {
        setPage(1);
        setFilter(formData.filter);
    }

    return (
        <>
            <Card py={2} px={4}>
                <CardHeader>
                    <Flex justify="space-between" align="center" minHeight="60px" w="100%">
                        <Text fontSize="lg" fontWeight="bold">
                            Estações
                        </Text>
                        <Button onClick={() => { setCurrentStation({}); onOpen() }} leftIcon={<FaPlusCircle />} colorScheme="whatsapp" fontSize="md">
                            Nova
                        </Button>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <Flex direction="column" w="100%">
                        <Flex direction="column" w="100%" overflowX={"auto"} mb={4}>
                            <Formik onSubmit={onFilterSubmit} initialValues={{ search: "" }}>
                                <Form id="filterForm">
                                    <Field name="filter">
                                        {({ field, form }) => (
                                            <FormControl>
                                                <SimpleGrid columns={12}>
                                                    <GridItem colSpan={{ base: 12, md: 10 }}>
                                                        <Input {...field} mb={{ base: 2, md: 0 }} borderRightRadius={{ base: 10, md: 0 }} placeholder="Pesquisar por..." borderRadius="10px" fontSize="sm" size="md" pr='4.5rem' />
                                                    </GridItem>
                                                    <GridItem colSpan={{ base: 12, md: 2 }}>
                                                        <Button size='md' borderLeftRadius={{ base: 15, md: 0 }} type="submit" w="100%" leftIcon={<FaSearch />}>
                                                            Buscar
                                                        </Button>
                                                    </GridItem>
                                                </SimpleGrid>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Form>
                            </Formik>
                        </Flex>
                        <Flex direction="column" w="100%">
                            <Flex direction="column" w="100%" overflowX={"auto"}>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>Provedor</Th>
                                            <Th>Identificador</Th>
                                            <Th>Cidade</Th>
                                            <Th>Estado</Th>
                                            <Th>Ações</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {data.data && data.data.length ?
                                            data.data.map((station) => (
                                                <StationRow
                                                    key={station.id}
                                                    station={station}
                                                    onRestoreOpen={() => { setRestoreAlertIsOpen(true) }}
                                                    onDeleteOpen={() => { setAlertIsOpen(true) }}
                                                    onEditOpen={onOpen}
                                                    onClick={() => { setCurrentStation(station) }}
                                                    onNormalRainOpen={normalRainClosure.onOpen}
                                                    onLogsOpen={logsClosure.onOpen}
                                                />
                                            ))
                                            :
                                            (
                                                <Tr>
                                                    <Td colSpan={5} textAlign={"center"} color="gray.500">Nenhuma estação encontrada</Td>
                                                </Tr>
                                            )
                                        }
                                    </Tbody>
                                </Table>
                            </Flex>
                            <Pagination changePage={getData} data={data} nextButtonText="Próxima" prevButtonText="Anterior" />
                            <Flex w={"100%"} justify="center" fontSize="sm" color="gray.500">
                                {
                                    data?.meta?.total ?
                                        (
                                            data.meta.filter ?
                                                <Text>{data.meta.total} registros encontradas para "{data.meta.filter}", mostrando de {data.meta.from} a {data.meta.to}</Text>
                                                :
                                                <Text>
                                                    Mostrando de {data.meta.from} a {data.meta.to} de {data.meta.total} registros
                                                </Text>
                                        )
                                        :
                                        ""
                                }
                            </Flex>
                        </Flex>
                    </Flex>
                </CardBody>
            </Card>
            <StationFormDrawer isOpen={isOpen} onClose={onClose} station={currentStation} getData={getData} />
            <DeleteStation station={currentStation} isOpen={isAlertOpen} onClose={onAlertClose} getData={getData} />
            <RestoreDataStation station={currentStation} isOpen={isRestoreAlertOpen} onClose={onRestoreAlertClose} />
            <StationNormalRainDrawer isOpen={normalRainClosure.isOpen} onClose={normalRainClosure.onClose} station={currentStation} getData={getData} />
            <StationLogs station={currentStation} isOpen={logsClosure.isOpen} onClose={logsClosure.onClose} />
        </>
    );
}

function StationRow({ station, onClick, onDeleteOpen, onEditOpen, onRestoreOpen, onNormalRainOpen, onLogsOpen }) {
    return (
        <Tr>
            <Td>{station.provider}</Td>
            <Td>
                {station.identifier}
                <br />
                {
                    !station.show_homepage ?
                        <>
                            <Text color="red" fontWeight={"semibold"} fontStyle="italic" fontSize={"xs"}>Escondida</Text>
                        </>
                        :
                        ''
                }
                <i>{station.description}</i>
            </Td>
            <Td>{station.city}</Td>
            <Td>{station.state}</Td>
            <Td textAlign={"center"}>
                <Flex gridGap={2} flexWrap={"wrap"} justify={"center"}>
                    {["Wunderground", "INMET"].indexOf(station.provider) !== -1 &&
                        <Button variant="outline" colorScheme="blue" size="sm" onClick={() => { onClick(); onRestoreOpen(); }} leftIcon={<FaHistory />}>
                            Restaurar
                        </Button>
                    }

                    <Button variant="outline" colorScheme="gray" size="sm" onClick={() => { onClick(); onEditOpen(); }} leftIcon={<FaPencilAlt />}>
                        Editar
                    </Button>

                    <Button variant="outline" colorScheme="purple" size="sm" onClick={() => { onClick(); onNormalRainOpen(); }} leftIcon={<FaTable />}>
                        Média Chuva
                    </Button>

                    <Button variant="outline" colorScheme="green" size="sm" onClick={() => { onClick(); onLogsOpen(); }} leftIcon={<FaList />}>
                        Logs
                    </Button>

                    <Button variant="outline" colorScheme="red" size="sm" onClick={() => { onClick(); onDeleteOpen(); }} leftIcon={<FaTrashAlt />}>
                        Excluir
                    </Button>
                </Flex>
            </Td>
        </Tr>
    );
}

function StationLogs({ station, isOpen, onClose }) {
    const loading = useLoading();
    const toast = useToast({
        position: "top-right"
    });

    const [page, setPage] = useState(1);
    const [data, setData] = useState({});
    const [filter, setFilter] = useState('');

    const getData = async (param) => {
        loading.show();
        let currentPage = param ? param.page : (page || 1);
        setPage(currentPage);
        let response = await StationsApi.logs(station.id,  currentPage, filter);
        setData(response.json);
        loading.hide();
    }

    useEffect(() => {
        if (station.id)
            getData({ page: 1 });
    }, [station]);

    const onFilterSubmit = (formData) => {
        loading.show();
        setPage(1);
        setFilter(formData.filter);
        setTimeout(() => {
            getData();
        }, 400);
    }

    const theme = useColorModeValue("rjv-default", "monokai");

    return (
        <AlertDialog isOpen={isOpen} onClose={onClose} size="6xl">
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Logs da Estação
                        <Text fontSize={"sm"} opacity={.75} fontWeight={"semibold"}>{station.identifier} - {station.city}/{station.state}</Text>
                    </AlertDialogHeader>

                    <AlertDialogBody>

                    <Flex direction="column" w="100%">
                        <Flex direction="column" w="100%" overflowX={"auto"} mb={4}>
                            <Formik onSubmit={onFilterSubmit} initialValues={{ search: "" }}>
                                <Form id="filterForm">
                                    <Field name="filter">
                                        {({ field }) => (
                                            <FormControl>
                                                <SimpleGrid columns={12}>
                                                    <GridItem colSpan={{ base: 12, md: 10 }}>
                                                        <Input {...field} mb={{ base: 2, md: 0 }} borderRightRadius={{ base: 10, md: 0 }} placeholder="Pesquisar por..." borderRadius="10px" fontSize="sm" size="md" pr='4.5rem' />
                                                    </GridItem>
                                                    <GridItem colSpan={{ base: 12, md: 2 }}>
                                                        <Button size='md' borderLeftRadius={{ base: 15, md: 0 }} type="submit" w="100%" leftIcon={<FaSearch />}>
                                                            Buscar
                                                        </Button>
                                                    </GridItem>
                                                </SimpleGrid>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Form>
                            </Formik>
                        </Flex>
                        <Flex direction="column" w="100%">
                            <Flex direction="column" w="100%" overflowX={"auto"}>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>Dados</Th>
                                            <Th>Momento</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {data.data && data.data.length ?
                                            data.data.map((stationLog) => (
                                                <Tr>
                                                    <Td>
                                                        <ReactJson src={JSON.parse(stationLog.data)} collapsed={2} theme={theme} />
                                                    </Td>
                                                    <Td>{stationLog.created_at}</Td>
                                                </Tr>
                                            ))
                                            :
                                            (
                                                <Tr>
                                                    <Td colSpan={2} textAlign={"center"} color="gray.500">Nenhum log encontrado</Td>
                                                </Tr>
                                            )
                                        }
                                    </Tbody>
                                </Table>
                            </Flex>
                            <Pagination changePage={getData} data={data} nextButtonText="Próxima" prevButtonText="Anterior" />
                            <Flex w={"100%"} justify="center" fontSize="sm" color="gray.500">
                                {
                                    data?.meta?.total ?
                                        (
                                            data.meta.filter ?
                                                <Text>{data.meta.total} registros encontradas para "{data.meta.filter}", mostrando de {data.meta.from} a {data.meta.to}</Text>
                                                :
                                                <Text>
                                                    Mostrando de {data.meta.from} a {data.meta.to} de {data.meta.total} registros
                                                </Text>
                                        )
                                        :
                                        ""
                                }
                            </Flex>
                        </Flex>
                    </Flex>

                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button onClick={onClose}>
                            Cancelar
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

function RestoreDataStation({ station, isOpen, onClose }) {
    const loading = useLoading();
    const toast = useToast({
        position: "top-right"
    });
    async function onSubmit(formData, { setFieldError }) {
        loading.show();
        let response = await StationsApi.restore(station.id, formData);
        loading.hide();
        if (response.status == 200) {
            toast({ status: 'success', title: 'Restauração adicionada na fila de processamento!', duration: 3000, variant: 'solid', isClosable: true });
            onClose();
            getData();
        } else if (response.status === 422) {
            let errors = response.json.errors;
            for (let field in errors) {
                setFieldError(field, errors[field][0]);
            }
        } else {
            toast({ status: 'error', title: 'Houve um problema ao iniciar restauração! Cód. ' + response.status, duration: 3000, variant: 'solid', isClosable: true });
        }
    };

    return (
        <AlertDialog isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Restaurar Dados da Estação
                        <Text fontSize={"sm"} opacity={.75} fontWeight={"semibold"}>{station.identifier} - {station.city}/{station.state}</Text>
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        <Formik onSubmit={onSubmit} initialValues={{ from: "", to: "" }}>
                            <Form id="restoreData">
                                <SimpleGrid columns={{ sm: 1, lg: 2 }} gap={4}>
                                    <GridItem colSpan={2}>
                                        <Alert status="info" borderRadius={10} fontSize={"sm"}>
                                            <AlertIcon />
                                            <AlertDescription>Somente disponível para estações Wundergound e INMET</AlertDescription>
                                        </Alert>
                                    </GridItem>
                                    <Field name="from">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.from && form.touched.from} isRequired>
                                                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                                    À partir de:
                                                </FormLabel>
                                                <Input {...field} type="date" borderRadius="10px" fontSize="sm" size="md" />
                                                <FormErrorMessage>{form.errors.from}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name="to">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.to && form.touched.to} isRequired>
                                                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                                    Até:
                                                </FormLabel>
                                                <Input {...field} type="date" borderRadius="10px" fontSize="sm" size="md" />
                                                <FormErrorMessage>{form.errors.to}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </SimpleGrid>
                            </Form>
                        </Formik>
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button type='submit' form='restoreData' colorScheme='blue' ml={3}>
                            Restaurar
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

function DeleteStation({ station, isOpen, onClose, getData }) {
    const cancelRef = useRef();
    const toast = useToast({
        position: "top-right"
    });
    const onDelete = async () => {
        let response = await StationsApi.delete(station.id);
        if (response.status == 204) {
            toast({ status: 'success', title: 'Estação excluído com sucesso!', duration: 3000, variant: 'solid', isClosable: true });
            onClose();
            getData();
        } else {
            toast({ status: 'error', title: 'Houve um problema ao excluir a estação! Cód. ' + response.status, duration: 3000, variant: 'solid', isClosable: true });
        }
    };

    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Excluir Estação
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Você tem certeza que deseja excluir a estação: <b>{station.identifier}</b>?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Não, Cancelar
                        </Button>
                        <Button colorScheme='red' onClick={onDelete} ml={3}>
                            Sim, Excluir
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

function StationFormDrawer({ isOpen, onClose, station, getData }) {
    const loading = useLoading();
    const toast = useToast({
        position: "top-right"
    });

    const submitHandle = async (values) => {
        loading.show();
        let data = null;
        if (station.id) {
            data = await StationsApi.update(station.id, values);
        } else {
            data = await StationsApi.store(values);
        }

        if (data.status >= 200 && data.status < 300) {
            toast({ status: 'success', title: 'Estação salvo com sucesso!', duration: 3000, variant: 'solid', isClosable: true });
            loading.hide();
            getData();
            onClose();
        } else {
            toast({ status: 'error', title: 'Erro ao salvar a estação! Cód. ' + data.status, duration: 3000, variant: 'solid', isClosable: true });
            loading.hide();
        }
    }

    let initialValues = {
        provider: station.provider || null,
        identifier: station.identifier || null,
        state: station.state || null,
        city: station.city || null,
        description: station.description || null,
        lat: station.lat ?? 0,
        long: station.long ?? 0,
        elevation: station.elevation || null,
        show_homepage: station.show_homepage
    };

    const [map, setMap] = useState(<Skeleton height={200} />);
    const reloadMap = (form) => {
        setMap(<Skeleton height={200} />);
        setTimeout(() => {
            setMap(<SingleMarkerMap point={[form.values.lat || 0, form.values.long || 0]} text="Localização da Estação" />);
        }, 500);
    }
    useEffect(() => {
        setMap(<Skeleton height={200} />);
        setTimeout(() => {
            setMap(<SingleMarkerMap point={[station.lat, station.long]} text="Localização da Estação" />);
        }, 500);
    }, [station]);

    const loadData = async (form) => {
        loading.show();
        let data = await StationsApi.search({ identifier: form.values.identifier, provider: form.values.provider });
        if (data.status === 200) {
            let uf = data.json.state.length > 2 ? Helper.ufList().find(uf => uf.full_name === data.json.state).uf || 'null' : data.json.state;
            form.values.lat = data.json.lat;
            form.values.long = data.json.long;
            reloadMap(form);
            form.setFieldValue('lat', data.json.lat);
            form.setFieldValue('long', data.json.long);
            form.setFieldValue('elevation', data.json.elev);
            form.setFieldValue('state', uf);
            form.setFieldValue('city', data.json.city);
        } else if (data.status === 204) {
            toast({ status: 'warning', title: 'Nenhum dado retornado, identificador inválido/estação desligada!', duration: 3000, variant: 'solid', isClosable: true });
        } else {
            toast({ status: 'error', title: 'Erro ao buscar estação! Cód. ' + data.status, duration: 3000, variant: 'solid', isClosable: true });
        }
        loading.hide();
    }

    return (
        <Drawer isOpen={isOpen} onClose={onClose} size="md">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>{station.id ? 'Editar' : 'Nova'} Estação</DrawerHeader>
                <DrawerBody>

                    <Formik onSubmit={submitHandle} initialValues={initialValues} enableReinitialize={true}>
                        {props => (
                            <Form id="stationForm">
                                <Flex flexDirection="row" flexWrap w="100%">
                                    <SimpleGrid w="100%" columns={12} gap={4}>
                                        <GridItem colSpan={5}>
                                            <Field name="provider">
                                                {({ field, form }) => (
                                                    <FormControl isRequired={!station.id} isDisabled={!!station.id}>
                                                        <FormLabel mb="0" htmlFor="provider" fontWeight="normal">Provedor:</FormLabel>
                                                        <Select id="provider" {...field} borderRadius="10px" fontSize="md" placeholder="Selecione...">
                                                            <option value="Wunderground">Wunderground</option>
                                                            <option value="INMET">INMET</option>
                                                            <option value="WeatherLink">WeatherLink</option>
                                                            <option value="AmbientWeather">AmbientWeather</option>
                                                            <option value="Lora">Lora (Passivo)</option>
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>
                                        <GridItem colSpan={5}>
                                            <Field name="identifier">
                                                {({ field, form }) => (
                                                    <FormControl isRequired={!station.id} isDisabled={!!station.id}>
                                                        <FormLabel mb="0" htmlFor="identifier" whiteSpace={"nowrap"} fontWeight="normal">Identificador:</FormLabel>
                                                        <Input id="identifier" {...field} borderRadius="10px" fontSize="md" type="text" placeholder="IESTACAO2" />
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>

                                        <GridItem colSpan={2}>
                                            <IconButton colorScheme={"blue"} w="100%" mt={6} onClick={() => loadData(props)} icon={<FaSearch />} />
                                        </GridItem>


                                        <GridItem colSpan={9}>
                                            <Field name="city">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel mb="0" htmlFor="city" fontWeight="normal">Cidade:</FormLabel>
                                                        <Input id="city" {...field} borderRadius="10px" fontSize="md" type="text" placeholder="Porto Alegre" />
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>
                                        <GridItem colSpan={3}>
                                            <Field name="state">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel mb="0" htmlFor="state" fontWeight="normal">UF:</FormLabel>
                                                        <Select id="state" {...field} borderRadius="10px" fontSize="md" placeholder="Selecione...">
                                                            {Helper.ufList().map(uf => <option key={uf.uf} value={uf.uf}>{uf.uf}</option>)}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>

                                        <GridItem colSpan={{ sm: 6, lg: 4 }}>
                                            <Field name="lat">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel mb="0" htmlFor="lat" fontWeight="normal">Latitude:</FormLabel>
                                                        <Input id="lat" {...field} borderRadius="10px" fontSize="md" type="text" onBlur={(e) => { reloadMap(form); form.handleBlur(e); }} />
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>
                                        <GridItem colSpan={{ sm: 6, lg: 4 }}>
                                            <Field name="long">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel mb="0" htmlFor="long" fontWeight="normal">Longitude:</FormLabel>
                                                        <Input id="long" {...field} borderRadius="10px" fontSize="md" type="text" onBlur={(e) => { reloadMap(form); form.handleBlur(e); }} />
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>
                                        <GridItem colSpan={{ sm: 12, lg: 4 }}>
                                            <Field name="elevation">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel mb="0" htmlFor="long" fontWeight="normal">Elevação (m):</FormLabel>
                                                        <Input id="elevation" {...field} borderRadius="10px" fontSize="md" />
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>


                                        <GridItem colSpan={12}>
                                            <Field name="description">
                                                {({ field, form }) => (
                                                    <FormControl>
                                                        <FormLabel mb="0" htmlFor="description" fontWeight="normal">Descrição Adicional:</FormLabel>
                                                        <Input id="description" {...field} borderRadius="10px" fontSize="md" />
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>

                                        <GridItem colSpan={12}>
                                            <Field name="show_homepage">
                                                {({ field, form }) => (
                                                    <FormControl>
                                                        <FormLabel mb="0" htmlFor="show_homepage" fontWeight="normal">Exibir na tela inicial:</FormLabel>
                                                        <Select id="show_homepage" {...field} borderRadius="10px" fontSize="md" defaultValue={'true'} placeholder="Selecione...">
                                                            <option value="true">Sim</option>
                                                            <option value="false">Não</option>
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>

                                        <GridItem colSpan={12}>
                                            {(props.values.lat != 0 && props.values.long != 0 && map) || <Skeleton height={200} />}
                                        </GridItem>

                                    </SimpleGrid>
                                </Flex>
                            </Form>
                        )}
                    </Formik>


                </DrawerBody>
                <DrawerFooter>
                    <Button variant='outline' mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button type='submit' form='stationForm' colorScheme="whatsapp">
                        Salvar
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
}


function StationNormalRainDrawer({ isOpen, onClose, station, getData }) {
    const loading = useLoading();
    const toast = useToast({
        position: "top-right"
    });

    const submitHandle = async (values) => {
        loading.show();
        let data = await StationsApi.store_rain_data(station.id, values);

        if (data.status >= 200 && data.status < 300) {
            toast({ status: 'success', title: data.json.message, duration: 3000, variant: 'solid', isClosable: true });
            loading.hide();
            getData();
            onClose();
        } else {
            toast({ status: 'error', title: 'Erro ao salvar dados! Cód. ' + data.status, duration: 6000, variant: 'solid', isClosable: true });
            loading.hide();
        }
    }

    let initialValues = {
        jan: station?.normal_rain?.jan || 0,
        feb: station?.normal_rain?.feb || 0,
        mar: station?.normal_rain?.mar || 0,
        apr: station?.normal_rain?.apr || 0,
        may: station?.normal_rain?.may || 0,
        jun: station?.normal_rain?.jun || 0,
        jul: station?.normal_rain?.jul || 0,
        aug: station?.normal_rain?.aug || 0,
        sep: station?.normal_rain?.sep || 0,
        oct: station?.normal_rain?.oct || 0,
        nov: station?.normal_rain?.nov || 0,
        dec: station?.normal_rain?.dec || 0,
        copy: '1'
    };

    let keys = Object.keys(initialValues);
    keys.pop();

    function translateMonthName(number) {
        switch (number) {
            case 'jan': return 'Janeiro';
            case 'feb': return 'Fevereiro';
            case 'mar': return 'Março';
            case 'apr': return 'Abril';
            case 'may': return 'Maio';
            case 'jun': return 'Junho';
            case 'jul': return 'Julho';
            case 'aug': return 'Agosto';
            case 'sep': return 'Setembro';
            case 'oct': return 'Outubro';
            case 'nov': return 'Novembro';
            case 'dec': return 'Dezembro';
        }
    }

    return (
        <Drawer isOpen={isOpen} onClose={onClose} size="lg">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Precipitação Normal da Estação (mm)<br />{station.provider} - {station.identifier}</DrawerHeader>
                <DrawerBody>

                    <Formik onSubmit={submitHandle} initialValues={initialValues} enableReinitialize={true}>
                        {props => (
                            <Form id="stationNormalRainForm" key={"stationNormalRainForm"}>
                                <SimpleGrid w="100%" gap={2} columns={{ sm: 1, md: 2, lg: 3 }}>
                                    {
                                        keys.map((month, index) => <GridItem>
                                            <Field name={month} key={index}>
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel mb="0" htmlFor={month} whiteSpace={"nowrap"} fontWeight="normal">{(index + 1).toString().padStart(2, '0')} - {translateMonthName(month)}:</FormLabel>
                                                        <Input
                                                            id={month}
                                                            {...field}
                                                            borderRadius="10px"
                                                            fontSize="md"
                                                            type="number"
                                                            step="0.01"
                                                        />
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>
                                        )
                                    }
                                </SimpleGrid>
                                <Field name={`copy`}>
                                    {({ field, form }) => (
                                        <FormControl isRequired mt={4}>
                                            <FormLabel mb="0" htmlFor={`replicate`} whiteSpace={"nowrap"} fontWeight="normal">Replicar dados para mesma cidade?</FormLabel>
                                            <Select id="copy" {...field} borderRadius="10px" fontSize="md" placeholder="Selecione...">
                                                <option value="0">Não</option>
                                                <option value="1">Sim, copiar</option>
                                            </Select>
                                            <FormHelperText fontSize={"xs"}>
                                                Selecione "Sim, copiar" para replicar os dados para todas as estações num raio de 50km.
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                </Field>
                            </Form>
                        )}
                    </Formik>


                </DrawerBody>
                <DrawerFooter>
                    <Button variant='outline' mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button type='submit' form='stationNormalRainForm' colorScheme="whatsapp">
                        Salvar
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
}

export default Stations;
