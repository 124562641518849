import Storage from "Helpers/Storage";
import { FaClock, FaCloudRain, FaExclamationTriangle, FaHome, FaLock, FaMap, FaReceipt, FaSeedling, FaTable, FaThermometerHalf, FaUsers } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";
import { RiBaseStationFill, RiBaseStationLine } from "react-icons/ri";
import Account from "views/Member/Account";
import Stations from "views/Member/Admin/Stations";
import Users from "views/Member/Admin/Users";
import Logout from "views/Member/Logout";
import MemberHome from "views/Member/MemberHome";
import ColdReport from "views/Member/Reports/ColdReport";
import MonthDataReport from "views/Member/Reports/MonthDataReport";
import YearTempReport from "views/Member/Reports/YearTempReport";
import RainDataReport from "views/Member/Reports/RainDataReport";
import NotFound from "views/Pages/NotFound";
import Cultures from "views/Member/Admin/Cultures";
import ModuleLocked from "views/Member/ModuleLocked";
import BalancoHidrico from "views/Member/Reports/BalancoHidrico";
import Notices from "views/Member/Notices";

var memberRoutes = [
    {
        path: "/members/home",
        name: "Página Inicial",
        component: MemberHome,
        icon: <FaHome/>,
        type: 'member'
    },
    {
        path: "/members/reports/cold",
        name: "Horas Frio",
        icon: <FaClock/>,
        lock: true,
        component: ColdReport,
        type: 'member'
    },
    {
        path: "/members/reports/data/month",
        name: "Relatório Mensal",
        icon: <FaTable/>,
        lock: true,
        component: MonthDataReport,
        type: 'member'
    },
    {
        path: "/members/reports/data/year",
        name: "Relatório Anual",
        icon: <FaTable/>,
        lock: true,
        component: YearTempReport,
        type: 'member'
    },
    {
        path: "/members/reports/rain/year",
        name: "Chuva Mensal",
        icon: <FaCloudRain/>,
        lock: true,
        component: RainDataReport,
        type: 'member'
    },
    {
        path: "/members/notices",
        name: "Alertas",
        icon: <FaExclamationTriangle/>,
        lock: true,
        component: Notices,
        type: 'member'
    },
    {
        path: "/members/reports/balanco-hidrico",
        name: "Balanço Hídrico",
        icon: <FaSeedling/>,
        lock: true,
        component: BalancoHidrico,
        type: 'member',
        isModule: true,
        userHas: 'bhs',
    },
    {
        path: "/members/account",
        name: "Minha Conta",
        component: Account,
        icon: <FaReceipt />,
        type: 'member'
    },
    {
        path: "/",
        name: "Mapa",
        icon: <FaMap />,
        type: 'member'
    },
    {
        path: "/members/logout",
        name: "Logout",
        icon: <IoIosLogOut />,
        component: Logout,
        type: 'member'
    },
    {
        path: "/members/module-locked",
        name: "Módulo Bloqueado",
        component: ModuleLocked,
    },



    {
        path: "/members/admin/stations",
        name: "Estações",
        icon: <RiBaseStationLine />,
        component: Stations,
        type: 'admin'
    },
    {
        path: "/members/admin/users",
        name: "Usuários",
        icon: <FaUsers />,
        component: Users,
        type: 'admin'
    },
    {
        path: "/members/admin/cultures",
        name: "Culturas",
        icon: <FaSeedling />,
        component: Cultures,
        type: 'admin'
    },
    {
        path: "*",
        name: "404",
        component: NotFound,
    },
];
export default memberRoutes;
