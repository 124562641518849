import Estacoes from "views/Public/Estacoes";
import HeatmapPage from "views/Public/HeatmapPage";
import Home from "views/Public/Home";
import StationDetails from "views/Public/StationDetails";

var publicRoutes = [
    {
        path: "/estacao/:id",
        name: "Station",
        component: StationDetails,
    },
    {
        path: "/",
        name: "Mapa",
        component: Home,
    },
    {
        path: "/estacoes",
        name: "Estacoes",
        component: Estacoes,
    },
    // TODO - REMOVE
    {
        path: "/heat",
        name: "Heatmap",
        component: HeatmapPage,
    },
];
export default publicRoutes;
