import Client from './Client';

export const StationsApi = {
    homepage: async() => {
        return await Client.get('/v1/stations/homepage');
    },
    ranking: async() => {
        return await Client.get('/v1/stations/ranking');
    },
    list: async (page, filter = '') => {
        return await Client.get(`/v1/stations?page=${page}&filter=${filter}`);
    },
    options: async () => {
        return await Client.get(`/v1/stations/options`);
    },
    search: async (formData) => {
        return await Client.post(`/v1/stations/search`, formData);
    },
    store: async (formData) => {
        return await Client.post(`/v1/stations`, formData);
    },
    update: async (id, formData) => {
        return await Client.put(`/v1/stations/${id}`, formData);
    },
    delete: async (id) => {
        return await Client.destroy(`/v1/stations/${id}`);
    },
    detail: async(id) => {
        return await Client.get(`/v1/stations/${id}/detail`);
    },
    restore: async (id, formData) => {
        return await Client.post(`/v1/stations/${id}/restore`, formData);
    },
    forecast: async(id) => {
        return await Client.get(`/v1/stations/${id}/forecast`);
    },
    forecast16: async(id) => {
        return await Client.get(`/v1/stations/${id}/forecast16`);
    },
    rain_data: async(id) => {
        return await Client.get(`/v1/stations/${id}/rain_data`);
    },
    store_rain_data: async(id, formData) => {
        return await Client.post(`/v1/stations/${id}/rain_data`, formData);
    },
    logs: async (id, page, filter = '') => {
        return await Client.get(`/v1/stations/${id}/logs?page=${page}&filter=${filter}`);
    },
};

export default StationsApi;
