import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Radio,
    RadioGroup,
    Select,
    Spinner,
    Stack,
    Text,
    useDisclosure,
    useMediaQuery
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import brazilGeoJson from "../../assets/brazil";
import StationsApi from "Api/StationsApi";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { getCor, renderStationMarkers } from "./Home";
import { divIcon } from "leaflet";

export default function HeatmapPage() {
    const [stations, setStations] = useState([]);
    const [isLoadingStations, setIsLoadingStations] = useState(true);
    const [heatOptions, setHeatOptions] = useState({
        currentDataType: 'temp', resolution: "5", opacity: "0.6", exp: "3", show_stations: "0"
    });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoadingHeat, setLoadingHeat] = useState(true);

    window.mapMarkerType = heatOptions.currentDataType;

    let center = [-14.4095261, -51.31668];

    const [isMobile] = useMediaQuery("(max-width: 768px)");

    useEffect(() => {
        loadStations();
    }, []);


    async function loadStations() {
        setIsLoadingStations(true);
        let data = await StationsApi.homepage();
        setStations(data.json || []);
        setIsLoadingStations(false);
    }

    return (
        <>
            <link
                rel="stylesheet"
                href="https://unpkg.com/react-leaflet-markercluster/dist/styles.min.css"
            />


            <Box className="map-drawer-btn" zIndex={99}>
                <Flex
                    align="center"
                    direction={"column"}
                    cursor={"pointer"}
                    onClick={onOpen}
                >
                    <Text fontSize={"sm"}>Configurações</Text>
                </Flex>
            </Box>

            <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Mapa de Calor</DrawerHeader>

                    <DrawerBody>
                        <form onSubmit={(e) => {
                            e.preventDefault();

                            var formData = new FormData(e.target);

                            onClose();

                            setHeatOptions({
                                ...Object.fromEntries(formData)
                            });
                        }}>
                            <Text fontWeight={"semibold"}>Dado a ser exibido</Text>
                            <RadioGroup
                                defaultValue={heatOptions.currentDataType}
                                mt={2}
                                name="currentDataType"
                            >
                                <Stack direction="column">
                                    <Radio value="temp">Temperatura Atual (°C)</Radio>
                                    <Radio value="temp_min">Temperatura Mínima (°C)</Radio>
                                    <Radio value="temp_max">Temperatura Máxima (°C)</Radio>
                                </Stack>
                            </RadioGroup>

                            <Text mt={4} fontWeight={"semibold"}>Resolução</Text>
                            <Select name="resolution" defaultValue={heatOptions.resolution}>
                                <option value="1">1px</option>
                                <option value="2">2px</option>
                                <option value="3">3px</option>
                                <option value="5">5px</option>
                                <option value="7">7px</option>
                                <option value="10">10px</option>
                            </Select>

                            <Text mt={4} fontWeight={"semibold"}>Opacidade</Text>
                            <Select name="opacity" defaultValue={heatOptions.opacity}>
                                <option value="0.2">20%</option>
                                <option value="0.3">30%</option>
                                <option value="0.4">40%</option>
                                <option value="0.5">50%</option>
                                <option value="0.6">60%</option>
                                <option value="0.7">70%</option>
                                <option value="0.8">80%</option>
                            </Select>


                            <Text mt={4} fontWeight={"semibold"}>Exponente</Text>
                            <Select name="exp" defaultValue={heatOptions.exp}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                            </Select>

                            <Text mt={4} fontWeight={"semibold"}>Mostrar Estações</Text>
                            <Select name="show_stations" defaultValue={heatOptions.show_stations}>
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </Select>


                            <Flex mt={4} justify={"center"}>
                                <Box>
                                    <Button colorScheme="blue" type="submit">Aplicar</Button>
                                </Box>
                            </Flex>
                        </form>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant="outline" mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>

            <Box position="relative" marginTop={isMobile ? "70px" : "50px"}>
                <MapContainer
                    center={center}
                    zoom={4}
                    style={{
                        position: "fixed",
                        top: isMobile ? "70px" : "50px",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        width: "100%",
                        borderRadius: "15px !important",
                        zIndex: 0,
                    }}
                    attributionControl={false}
                >
                    <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="OpenStreetMap" />

                    {!isLoadingStations && <HeatMap data={stations} {...heatOptions} isLoading={isLoadingHeat} setLoading={setLoadingHeat} />}

                    {heatOptions.show_stations == "1" &&
                    <MarkerClusterGroup
                        maxClusterRadius={50}
                        disableClusteringAtZoom={7}
                        iconCreateFunction={(cluster) => {
                            let data = [];
                            const childMarkers = cluster.getAllChildMarkers();

                            childMarkers.forEach((childMarker) => {
                                let value = childMarker.options.icon.options.className;

                                if (value != "x") {
                                    data.push(Number(value));
                                }
                            });

                            let avg = (
                                data.reduce((a, b) => a + b, 0) / childMarkers.length
                            ).toFixed(1);

                            return divIcon({
                                iconSize: [50, 30],
                                html: `<div style="background-color: ${getCor(
                                    avg
                                )}; color:white;" class="icone icone-cluster icone-${getCor(
                                    avg
                                ).replace('#', '')}">~${avg}<br>${cluster._childCount
                                    } estações</div>`,
                                popupAnchor: [60, 0],
                            });
                        }}
                    >
                        {renderStationMarkers(stations)}
                    </MarkerClusterGroup>}

                </MapContainer>
                <style>
                    {`
                .icone, .icone-cluster {
                    opacity: 0.6 !important
                }

                .leaflet-div-icon {
                    background: transparent !important;
                }
                `}
                </style>

                {
                    isLoadingStations && <Flex zIndex={999} position={"fixed"} bottom={0} left={0} right={0} p={4} justify={"center"}>
                        <Flex fontSize={"sm"} className="theme-bg" align="center" justify={"center"} p={2} rounded="md" border={"1px solid"} borderColor="blue.500" shadow={"lg"} bg="white" fontWeight={"semibold"}>
                            <Spinner size="sm" />
                            <Text ml={2}>Carregando estações...</Text>
                        </Flex>
                    </Flex>
                }
            </Box>
        </>
    );
}

function HeatMap({ data, currentDataType = 'temp', resolution = 5, opacity = 0.6, exp = 3, isLoading, setLoading }) {
    const map = useMap();

    window.map = map;

    useEffect(() => {
        // Masking everything outside Brazil
        var worldPolygon = turf.polygon([[
            [-180, -90], [180, -90], [180, 90], [-180, 90], [-180, -90]
        ]]);
        var maskPolygon = turf.difference(worldPolygon, brazilGeoJson);

        L.geoJSON(maskPolygon, {
            style: {
                fillColor: '#ddd',
                fillOpacity: 1,
                color: 'white',
                opacity: 1
            }
        }).addTo(map);

        // Adding Brazil's GeoJSON
        L.geoJSON(brazilGeoJson, {
            style: {
                fillColor: 'transparent',
                fillOpacity: 0,
                color: 'transparent',
                weight: 0
            }
        }).addTo(map);
    }, [])

    function getData(station) {
        switch (currentDataType) {
            case "temp":
                return station.last_data.temp;
            case "temp_max":
                return station.temp_max;
            case "temp_min":
                return station.temp_min;
        }
    }

    useEffect(() => {
        setTimeout(() => {
            window.heatmapLayer = L.idwLayer(
                data.map(s => {
                    return [
                        s.lat, s.long, getData(s)
                    ]
                }),
                {
                    opacity: parseFloat(opacity),
                    cellSize: parseInt(resolution),
                    exp: parseInt(exp),
                    max: 40,
                    min: -5,
                    onProcessStart: () => setLoading(true),
                    onProcessEnd: () => setLoading(false)
                }
            ).addTo(window.map);
        }, 1500);

        return () => {
            if (window.heatmapLayer) {
                map.removeLayer(window.heatmapLayer);
            }
        }
    }, [currentDataType, resolution, opacity, exp]);

    // L.idwLayer(parsedStations, {opacity: 0.3, cellSize: 10, exp: 2, max: 1200}).addTo(map);

    return isLoading ? <Flex zIndex={999} position={"absolute"} bottom={0} left={0} right={0} p={4} justify={"center"}>

        <Flex fontSize={"sm"} className="theme-bg" align="center" justify={"center"} p={2} rounded="md" border={"1px solid"} borderColor="blue.500" shadow={"lg"} bg="white" fontWeight={"semibold"}>
            <Spinner size="sm" />
            <Text ml={2}>Gerando mapa de calor</Text>
        </Flex>

    </Flex> : <></>;
}
